@import "../../../styles/base/abstracts";

.hbs-pullquote {
  @include block-spacing($prop: margin, $size: lg);
}

.hbs-pullquote__text {
  @include big-paragraph($ff-serif, $font-weight: $fw-light);
  position: relative;

  .hbs-global-align-wide & {
    @include h3($ff-serif, $fw-light);
    line-height: $lh-sm;
  }

  p {
    margin-bottom: $spacing-md;
    &:first-child::before {
      @include absolute(0 100% x x);
      font-family: $ff-serif;
    }

    &:last-of-type::after {
      font-family: $ff-serif;
    }
  }
}

.hbs-pullquote__text:not(.hidequote) {
  p {
    &:first-child::before {
      content: "“";
    }
    &:last-of-type::after {
      content: "”";
    }
  }
}

.hbs-pull-quote__attribution {
  @include baseline-text($ff-sans);
  align-items: center;
  display: flex;
  font-weight: $fw-reg;

  .hbs-media-asset {
    @include size(60px);
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: $spacing-md;
    overflow: hidden;
  }
}

.hbs-global-align-full .hbs-pullquote__content {
  margin-left: 1%;
}
@include mq($bp-desktop-lg) {
  .hbs-global-align-full .hbs-pullquote__content {
    margin-right: auto;
    width: $col-12;
  }
}
@include mq($bp-desktop-xl) {
  .hbs-global-align-full .hbs-pullquote__content {
    margin-right: auto;
    width: $col-10;
  }
}
